import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import MapView from "./MapView";
import logo from "../assets/bce.png";
import { makeStyles } from "@material-ui/core/styles";
import TableView from "./TableView";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles(theme => ({
  content: {
    flex: "1 1 auto"
  },
  header: {
    alignItems: "center",
    flex: "0 1 auto",
    justifyContent: "space-between",
    margin: "0 auto",
    maxWidth: 800,
    padding: "16px"
  },
  headerAndLogo: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
  },
  logo: {
    [theme.breakpoints.down("sm")]: { margin: "0 auto" },
    [theme.breakpoints.up("sm")]: { margin: "0" },
    display: "block",
    float: 'left',
    padding: "16px 16px 0",
    width: 190,
  },
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100%"
  },
  spinner: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center"
  },
  titleAndToggle: {
    display: "flex",
    "& h2": {
      margin: 0
    },
    "& svg": {
      height: "16px",
      width: "16px"
    }
  },
  toggle: {
    "& svg": {
      height: "1.3em",
      width: "1.3em"
    }
  },
  tabpanel: {
    width: "100%",
    height: "90%",
    paddingTop: "10px"
  }
}));

function TabPanel(props) {
  const { children, classes, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      className={classes.tabpanel}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const App = () => {
  const classes = useStyles();

  const [pollingPlaces, setPollingPlaces] = useState(null);

  useEffect(() => {
    fetch("https://json-proxy.onrender.com/wait-times.json")
      .then(res => res.json())
      .then(data => setPollingPlaces(data.query_result.data.rows));
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handler = window.addEventListener("VoteAmericaEvent", function(evt) {
      console.log(evt); // This can be replaced with any function
    });
    return () => {
      window.removeEventListener("VoteAmericaEvent", handler);
    };
  });

  return (
    <div className={classes.root}>
      <div className={classes.headerAndLogo}>
        <img
          className={classes.logo}
          src={logo}
          alt="Boulder County Elections"
        />
        <div className={classes.header}>
          <div className={classes.titleAndToggle}>
            <h2>Vote Center Wait Times</h2>
            <Tooltip
              enterTouchDelay={0}
              title="Data provided by poll workers"
              placement="top-start"
            >
              <HelpOutlineIcon />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Map" {...a11yProps(0)} />
            <Tab label="List" {...a11yProps(1)} />
            <Tab label="Near Me" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel classes={classes} value={value} index={0}>
          {pollingPlaces ? (
            <MapView pollingPlaces={pollingPlaces} />
          ) : (
            <CircularProgress />
          )}
        </TabPanel>
        <TabPanel classes={classes} value={value} index={1}>
          {pollingPlaces ? (
            <TableView data={pollingPlaces} />
          ) : (
            <CircularProgress />
          )}
        </TabPanel>
        <div
          role="tabpanel"
          hidden={value !== 2}
          id={`nav-tabpanel-2`}
          aria-labelledby={`nav-tab-2`}
          className={classes.tabPanel}
        >
          <div
            className="voteamerica-embed"
            data-subscriber="public"
            data-tool="locate"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default App;
