import React from "react";
import { buildDirectionsUrl } from "../util/googleMaps";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import openInNew from "../assets/openInNew.png";
import prettyWaitTime from "../util/prettyWaitTime";

import L from "leaflet";

import GreenIcon from "../assets/leaflet-color-markers/img/marker-icon-2x-green.png";
import RedIcon from "../assets/leaflet-color-markers/img/marker-icon-2x-red.png";
import YellowIcon from "../assets/leaflet-color-markers/img/marker-icon-2x-yellow.png";
import Shadow from "../assets/leaflet-color-markers/img/marker-shadow.png";

function makeIcon(url) {
  return new L.Icon({
    iconUrl: url,
    shadowUrl: Shadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
}

const greenIcon = makeIcon(GreenIcon);
const redIcon = makeIcon(RedIcon);
const yellowIcon = makeIcon(YellowIcon);

function iconFor(place) {
  const icons = {
    fast: greenIcon,
    slow: redIcon,
    medium: yellowIcon
  };
  let icon = icons[place.wait_classification];
  if (icon) {
    return icon;
  } else {
    return null;
  }
}

const useStyles = makeStyles(() => ({
  mapLink: {
    display: "flex",
    alignItems: "center",
    paddingTop: 4,
    "& img": {
      height: 14,
      paddingLeft: 4,
      width: 14
    }
  },
  info: {
    fontSize: 14,
    "& h4": {
      margin: 0,
      paddingBottom: 4
    }
  },
  title: {
    fontWeight: "bold"
  },
  address: {
    paddingBottom: 12
  },
  popup: {
    "& p": {
      margin: "6px 0px"
    }
  },
  map: {
    height: "100%",
    width: "100%",
    margin: "auto"
  }
}));

const openInGoogleMaps = address =>
  window.open(buildDirectionsUrl(address), "_blank");

const CENTER_MAP = [40.08851475, -105.29907474999999];

const MapView = ({ pollingPlaces }) => {
  const classes = useStyles();
  const markers = pollingPlaces.map(place => (
    <Marker
      position={[place.lat, place.lon]}
      title={place.polling_station}
      icon={iconFor(place)}
    >
      <Popup className={classes.popup}>
        <p className={classes.title}>{place.polling_station}</p>
        <p className={classes.address}>{place.address}</p>
        <p>Wait Time: {prettyWaitTime(place.minutes_wait)}</p>

        <div
          className={classes.mapLink}
          onClick={() => openInGoogleMaps(place.address)}
        >
          Get directions <img src={openInNew} alt="Get directions" />
        </div>
      </Popup>
    </Marker>
  ));
  return (
    <Map center={CENTER_MAP} zoom={11} className={classes.map}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {markers}
    </Map>
  );
};

export default MapView;
