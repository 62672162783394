function prettyWaitTime(minutes) {
  if (minutes === 1) {
    return `1 minute`;
  } else if (minutes < 60) {
    return `${minutes} minutes`;
  } else {
    let hours = Math.round((10 * minutes) / 60) / 10;
    return `${hours} hours`;
  }
}

export default prettyWaitTime;
